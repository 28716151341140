import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { fetchDataSub } from "./redux/datasub/datasubActions";

import * as s from "./styles/globalStyles";
import styled from "styled-components";
// import { AudioPlayer } from "./AudioPlayer";
import Header from "./components/Header";
import About from "./components/About";
import Roadmap from "./components/Roadmap";
import Member from "./components/Member";
import Faq from "./components/Faq";
import Partners from "./components/Partners";
import Footer from "./components/Footer";
import CircleTextLogoLeft from "./components/CircleTextLogoLeft";
import CircleTextLogoRight from "./components/CircleTextLogoRight";
import useMedia from "use-media";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const MintTitle = styled.p`
  color: white;
  font-size: 30px;
  font-family: DelaGothicOne;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledInput = styled.input`
  color: #777;
  font-size: 2rem;
  border-radius: 5px;
  padding: 0.6rem 0.6rem;
  width: 50%;
  position: relative;
  overflow: hidden;
`;

export const StyledButton = styled.button`
  color: #fff;
  font-size: 2rem;
  font-family: DelaGothicOne;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonControl = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 20%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonReload = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 50%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const StyledButtonA = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--button-bg);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonWl = styled.button`
  color: #fff;
  font-size: 2rem;
  font-family: DelaGothicOne;
  border-radius: 10px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  cursor: pointer;

  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonPS = styled.button`
  color: #fff;
  font-size: 2rem;
  font-family: DelaGothicOne;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  cursor: pointer;

  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonUnlock = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: purple;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  font-family: DelaGothicOne;
  border-radius: 100%;
  border: none;
  background-color: #fff;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  display: none;
  @media (min-width: 900px) {
    width: 250px;
    display: block;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const datasub = useSelector((state) => state.datasub);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintAmount, setMintAmount] = useState(1);
  const [mintAmountWl, setMintAmountWl] = useState(1);
  const [minted, setminted] = useState(0);
  const [mintedWl, setmintedWl] = useState(0);
  const [wl, setwl] = useState(false);
  const [lang, setlang] = useState(false);
  const [whitelistProof, setwhitelistProof] = useState("");
  const [whitelistValid, setwhitelistValid] = useState(false);
  const [revealed, setrevealed] = useState(false);
  const [wlMaxMintNum, setwlMaxMintNum] = useState(3);
  const isNotSmp = useMedia({ minWidth: "500px" });
  const [langData, setlangData] = useState({
    fail: "Failed,Please try again.",
    chkops: "you minted NSK! check on your Opensea.",
    minting: "Minting NSK....",
    salestartwl: "Presale 1st Stage：11/26(Sat) 20:00〜(JST)",
    salestartwl2: "Presale 2nd Stage：11/26(Sat) 21:00〜(JST)",
    salestartpb: "Public Sale：11/26(Sat) 22:00〜(JST)",
    soldout: "Sold Out! thank you!",
    wl: "Presale",
    pb: "Public Sale",
    gasfee: "+gas Fee",
    network: "to get NSK,Please Connect to Ethereum Mainnet.",
    busy: "Busy",
    minted: "minted",
    youhavewl: "You are on the Whitelist!",
    youhavenotwl: "You are not Whitelisted.",
    mintwl: "mintWL",
    mintps: "mintPS",
    reload: "reload",
  });

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1650,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    DISPLAY_COSTWL: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  // const handleClick = (url, filename) => {
  //   alert("読みこむので少々お待ちください。");
  //   axios
  //     .get(url, {
  //       responseType: "blob",
  //     })
  //     .then((res) => {
  //       fileDownload(res.data, filename);
  //     });
  // };

  const claimNFTsWl = (amount) => {
    let cost = CONFIG.WEI_COSTWL;
    console.log("amount::" + String(amount));
    amount = Number(amount);
    // let amount = mintAmountWl;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * amount); //個数を１に固定0714(ふりっきー)
    let totalGasLimit = String(gasLimit * amount); //個数を1に固定0714(ふりっきー)
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`${CONFIG.NFT_NAME}${langData.minting}`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .whitelistMint(amount, whitelistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        // maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback(langData.fail);
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(langData.chkops);
        setClaimingNft(true);
        checkMintedwl();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTsPS = () => {
    let cost = CONFIG.WEI_COST;
    let amount = mintAmount;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * amount); //個数を１に固定0714(ふりっきー)
    let totalGasLimit = String(gasLimit * amount); //個数を1に固定0714(ふりっきー)
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`${CONFIG.NFT_NAME}${langData.minting}`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .psMint(amount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        // maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback(langData.fail);
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(langData.chkops);
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const setReveal = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`リビール中 ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setReveal(true)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setrevealed(true);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にリビールされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const withdraw = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`出金中 ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .withdraw()
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常に出金されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const setWLSaleEnable = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`WL.SALE 開始します ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setWhitelistSaleEnable(true)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にWLセール開始されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const setPublicSaleEnable = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`PBセールを開始します ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setPublicSaleEnable(true)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にPBセール開始されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const setBaseUrl = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`ベースURL設定します ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setBaseURI("https://startlands.io/NSK/")
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にベースURL設定されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };
  const setWlMaxMints = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    let maxMintNum = wlMaxMintNum;

    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`上限設定中 ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setWlMaxMints(maxMintNum)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `WL.MAX:${maxMintNum}::上限が設定されました。`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const changeLang = () => {
    setlang(!lang);
    let langJson = require("./data/jp.json");
    if (lang) {
      langJson = require("./data/en.json");
    }
    setlangData(langJson);
  };
  const checkWl = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      let address = blockchain.account;
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");
      let whitelist = require("./data/whitelist.json");
      let hashedAddresses = whitelist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      blockchain.smartContract.methods
        .isWhitelisted(blockchain.account, proof)
        .call()
        .then((receipt) => {
          setwhitelistProof(proof);
          setwhitelistValid(receipt);
          // console.log('whitelistValid/'+whitelistValid);
          dispatch(fetchData(blockchain.account));
        });
      // setwhitelistProof(proof);
      // setwhitelistValid(valid);
      // console.log("proof/" + proof);
      // console.log("root/" + root);
      // console.log(whitelistValid);
    }
  };

  const checkRevealed = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .revealed()
        .call()
        .then((receipt) => {
          setrevealed(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const checkMinted = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .psMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setminted(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const checkMintedwl = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .wlMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setmintedWl(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 2 - minted) {
      newMintAmount = 2 - minted;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };
  const getDataSub = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchDataSub(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const getReload = () => {
    location.reload();
  };

  useEffect(() => {
    getConfig();
    checkMinted();
    checkMintedwl();
    checkWl();
    checkRevealed();
  }, []);

  useEffect(() => {
    getData();
    getDataSub();
    checkMinted();
    checkMintedwl();
    checkRevealed();
    checkWl();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <Header />

      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "rgba(0, 0, 0,0)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.jpeg" : null}
      >
        <ul class="bgtrans">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <MintTitle
          id="mint"
          style={{
            textAlign: "center",
          }}
        >
          {"MINT"}
        </MintTitle>

        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <CircleTextLogoLeft />
            {/* <StyledImg
              alt={"example"}
              src={"/config/images/example.gif"}
              style={{ transform: "scaleX(-1)" }}
            /> */}
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "rgba(0,0,0,0.4)",
              padding: 24,
              borderRadius: 24,
              border: "4px var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            {blockchain.account === "" ||
            blockchain.smartContract === null ||
            blockchain.account === undefined ? (
              <></>
            ) : (
              <>
                {datasub.ownerAddress == blockchain.account ||
                "0x637d25D0769f747B2742A04d249802dA85395970" ==
                  blockchain.account ? (
                  <>
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      {!data.wlSaleStart ? (
                        <StyledButton
                          onClick={(e) => {
                            e.preventDefault();
                            setWLSaleEnable();
                            getData();
                          }}
                        >
                          {claimingNft ? "開始中" : "WLセール開始(8時)"}
                        </StyledButton>
                      ) : (
                        <></>
                      )}
                    </s.Container>
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledInput
                        value={wlMaxMintNum}
                        onChange={(event) =>
                          setwlMaxMintNum(event.target.value)
                        }
                      />
                      <StyledButtonControl
                        onClick={(e) => {
                          e.preventDefault();
                          setWlMaxMints();
                          getData();
                        }}
                      >
                        {claimingNft ? "Busy" : "WL上限設定(9時)"}
                      </StyledButtonControl>
                    </s.Container>

                    {!data.psSaleStart ? (
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledButton
                          onClick={(e) => {
                            e.preventDefault();
                            setPublicSaleEnable();
                            getData();
                          }}
                        >
                          {claimingNft ? "開始中" : "PBセール開始(10時)"}
                        </StyledButton>
                      </s.Container>
                    ) : (
                      <></>
                    )}

                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        onClick={(e) => {
                          e.preventDefault();
                          setBaseUrl();
                          getData();
                        }}
                      >
                        {claimingNft ? "Busy" : "URL設定(29/8時より前)"}
                      </StyledButton>
                    </s.Container>

                    {!revealed ? (
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledButton
                          onClick={(e) => {
                            e.preventDefault();
                            setReveal();
                            getData();
                          }}
                        >
                          {claimingNft ? "Busy" : "リビール(29/8時)"}
                        </StyledButton>
                      </s.Container>
                    ) : (
                      <></>
                    )}

                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        onClick={(e) => {
                          e.preventDefault();
                          withdraw();
                        }}
                      >
                        {claimingNft ? "Busy" : "出金(任意)"}
                      </StyledButton>
                    </s.Container>
                  </>
                ) : (
                  <></>
                )}{" "}
              </>
            )}

            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              LANG/言語
            </s.TextTitle>
            <div class="toggle-switch">
              <input
                id="toggleBox"
                class="toggle-input"
                type="checkbox"
                style={{ display: "none" }}
                onChange={(e) => {
                  // e.preventDefault();
                  changeLang();
                }}
              />
              <label for="toggleBox" class="toggle-label" />
            </div>
            <s.TextTitle style={{ textAlign: "center", color: "yellow" }}>
              {langData.salestartwl}
            </s.TextTitle>
            <s.TextTitle style={{ textAlign: "center", color: "yellow" }}>
              {langData.salestartwl2}
            </s.TextTitle>
            <s.TextTitle style={{ textAlign: "center", color: "yellow" }}>
              {langData.salestartpb}
            </s.TextTitle>

            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {/* <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                      <AudioPlayer></AudioPlayer>
                </s.TextDescription> */}
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {langData.soldout}
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  1 {CONFIG.SYMBOL} costs
                </s.TextTitle>
                <s.SpacerXSmall />

                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {langData.wl}
                  {" / "}
                  {CONFIG.DISPLAY_COSTWL}
                  {CONFIG.NETWORK.SYMBOL}
                  {/* {`(Max ${datasub.maxMintsPerWL}mint)`} */}
                </s.TextTitle>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {langData.pb}
                  {" / "}
                  {CONFIG.DISPLAY_COST}
                  {CONFIG.NETWORK.SYMBOL}
                  {/* {`(Max ${datasub.maxMintsPerPS}mint)`} */}
                </s.TextTitle>
                <s.SpacerXSmall />

                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {langData.gasfee}
                </s.TextDescription>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {langData.network}
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect(lang));
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.Container>
                      <s.Container>
                        {/* WLここから */}
                        {whitelistValid ? ( //ホワイトリスト所有確認
                          <>
                            {data.wlSaleStart ? ( //WLセール開始確認
                              <>
                                {mintedWl == datasub.maxMintsPerWL ? (
                                  <>
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledButtonWl
                                        disabled={1} //claimingNftPsがtrueなら disabledを表示させる。＝クリックできない
                                        onClick={(e) => {
                                          e.preventDefault();
                                        }}
                                      >
                                        {langData.minted}
                                      </StyledButtonWl>
                                    </s.Container>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {datasub.maxMintsPerWL - mintedWl == 1 ? (
                                  <>
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledButtonWl
                                        disabled={claimingNft ? 1 : 0} //claimingNftPsがtrueなら disabledを表示させる。＝クリックできない
                                        onClick={(e) => {
                                          e.preventDefault();
                                          claimNFTsWl(1);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? langData.busy : "1 Mint"}
                                      </StyledButtonWl>
                                    </s.Container>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {datasub.maxMintsPerWL - mintedWl == 2 ? (
                                  <>
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledButtonWl
                                        disabled={claimingNft ? 1 : 0} //claimingNftPsがtrueなら disabledを表示させる。＝クリックできない
                                        onClick={(e) => {
                                          e.preventDefault();
                                          claimNFTsWl(1);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? langData.busy : "1 Mint"}
                                      </StyledButtonWl>
                                    </s.Container>
                                    <s.SpacerXSmall />

                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledButtonWl
                                        disabled={claimingNft ? 1 : 0} //claimingNftPsがtrueなら disabledを表示させる。＝クリックできない
                                        onClick={(e) => {
                                          e.preventDefault();
                                          claimNFTsWl(2);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? langData.busy : "2 Mint"}
                                      </StyledButtonWl>
                                    </s.Container>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {datasub.maxMintsPerWL - mintedWl >= 3 ? (
                                  <>
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledButtonWl
                                        disabled={claimingNft ? 1 : 0} //claimingNftPsがtrueなら disabledを表示させる。＝クリックできない
                                        onClick={(e) => {
                                          e.preventDefault();
                                          claimNFTsWl(1);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? langData.busy : "1 Mint"}
                                      </StyledButtonWl>
                                    </s.Container>
                                    <s.SpacerXSmall />

                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledButtonWl
                                        disabled={claimingNft ? 1 : 0} //claimingNftPsがtrueなら disabledを表示させる。＝クリックできない
                                        onClick={(e) => {
                                          e.preventDefault();
                                          claimNFTsWl(2);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? langData.busy : "2 Mint"}
                                      </StyledButtonWl>
                                    </s.Container>

                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <StyledButtonWl
                                        disabled={claimingNft ? 1 : 0} //claimingNftPsがtrueなら disabledを表示させる。＝クリックできない
                                        onClick={(e) => {
                                          e.preventDefault();
                                          claimNFTsWl(3);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? langData.busy : "3 Mint"}
                                      </StyledButtonWl>
                                    </s.Container>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <s.TextDescription
                                    style={{
                                      color: "var(--accent-text)",
                                      fontFamily: "DelaGothicOne",
                                      fontSize: isNotSmp ? "1.5rem" : "1.1rem",
                                    }}
                                  >
                                    {langData.youhavewl}
                                  </s.TextDescription>
                                </s.Container>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <s.TextDescription
                                style={{
                                  color: "var(--accent-text)",
                                  fontFamily: "DelaGothicOne",
                                  fontSize: isNotSmp ? "1.5rem" : "1.1rem",
                                }}
                              >
                                {langData.youhavenotwl}
                              </s.TextDescription>
                            </s.Container>
                          </>
                        )}
                        {/* WLここまで */}
                      </s.Container>

                      {/* PSここから */}
                      {data.saleStart ? (
                        <>
                          {minted > 1 ? (
                            <>
                              <s.Container
                                ai={"center"}
                                jc={"center"}
                                fd={"row"}
                              >
                                <StyledButtonPS
                                  disabled={1} //claimingNftPsがtrueなら disabledを表示させる。＝クリックできない
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  {"PS.mint 済"}
                                </StyledButtonPS>
                              </s.Container>
                            </>
                          ) : (
                            <>
                              <s.SpacerMedium />
                              <s.Container>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <s.TextTitle
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {langData.pb + "("}
                                    {minted}
                                    {langData.minted + ")"}
                                  </s.TextTitle>
                                </s.Container>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <s.SpacerXSmall />
                                  <StyledRoundButton
                                    style={{ lineHeight: 0.4 }}
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      decrementMintAmount();
                                    }}
                                  >
                                    -
                                  </StyledRoundButton>
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {mintAmount}
                                  </s.TextDescription>
                                  <s.SpacerMedium />
                                  <StyledRoundButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      incrementMintAmount();
                                    }}
                                  >
                                    +
                                  </StyledRoundButton>
                                </s.Container>
                              </s.Container>
                              <s.SpacerSmall />
                              <s.Container
                                ai={"center"}
                                jc={"center"}
                                fd={"row"}
                              >
                                <StyledButtonPS
                                  disabled={claimingNft ? 1 : 0} //claimingNftPsがtrueなら disabledを表示させる。＝クリックできない
                                  onClick={(e) => {
                                    e.preventDefault();
                                    claimNFTsPS();
                                    getData();
                                  }}
                                >
                                  {claimingNft
                                    ? langData.busy
                                    : langData.mintps}
                                </StyledButtonPS>
                              </s.Container>
                              {minted > 0 ? <></> : <></>}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {/* PSここまで */}
                    </s.Container>
                    <s.SpacerLarge />

                    <StyledButtonReload
                      onClick={(e) => {
                        e.preventDefault();
                        getReload();
                      }}
                    >
                      {claimingNft ? "Busy" : langData.reload}
                    </StyledButtonReload>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <CircleTextLogoRight />
            {/* <StyledImg
              alt={"example"}
              src={"/config/images/example.gif"}
              style={{ transform: "scaleX(-1)" }}
            /> */}
          </s.Container>
        </ResponsiveWrapper>
      </s.Container>
      <About id="about" />
      <Roadmap id="roadmap" />
      <Member id="member" />
      <Faq id="faq" />
      <Partners id="partners" />
      <Footer config={CONFIG} />
    </s.Screen>
  );
}

export default App;
